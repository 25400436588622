import LocalData from "../persistencia/LocalData";
import AuthService from "./auth.service";
const getListaBaseConocimiento = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}lista-base-conocimiento`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegistrarBaseConocimiento = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}agregar-base-conocimientos`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": AuthService.getCurrentToken()
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegresaRegistroBaseConocimiento = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-registro-base-conocimiento`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": AuthService.getCurrentToken()
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarArchivos = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}borrar-archivo-base-conocimiento`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": AuthService.getCurrentToken()
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getComboBaseConocimiento = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}combo-base-conocimientos`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAdjuntosBaseConocimientos = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-adjuntos-baseconocimientos`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const BaseConocimiento = {
  getListaBaseConocimiento,
  postRegistrarBaseConocimiento,
  postRegresaRegistroBaseConocimiento,
  postBorrarArchivos,
  getComboBaseConocimiento,
  postAdjuntosBaseConocimientos
};

export default BaseConocimiento;
