
const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const filterOption = (input, option) => {
  const normalizedInput = removeAccents(input).toLowerCase();
  const normalizedOption = removeAccents(option?.nombre).toLowerCase();

  return normalizedOption.includes(normalizedInput);
};

const UtilsClass = {
  removeAccents,
  filterOption
};

export default UtilsClass;