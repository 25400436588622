import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Layout,
  theme,
  ConfigProvider,
  Space,
  Menu,
  Typography,
  Avatar,
  Badge,
  notification,
  Modal,
  Card,
  Button,
  Upload,
  Tooltip,
  Modal as ModalContrasena,
  Input,
  Spin,
  Tour,
  Table
} from "antd";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import NotificationsService from "../services/nofications.Service";
import TicketsService from "../services/tickets.Service";
import ReturnUserTypeClass from "../clases/returnUserType.class";
import UsuariosService from "../services/usuarios.Service";

import returnValidUploadFileClass from "../clases/returnValidUploadFile.class";

import LocalData from '../persistencia/LocalData';
import moment from "moment";
import ReturnStatusTag from "../componentes/ReturnStatusTagObject";
import RegresaTiempoTranscurrido from "../componentes/ReturnElapsedTime";

import Logo from '../componentes/Logo';

import "./StructureUser.css";
import {
  ProfileOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  ToolOutlined,
  BellOutlined,
  SettingOutlined,
  CloseCircleOutlined,
  ReadOutlined,
  SaveOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  BulbOutlined,
  AppstoreOutlined,
  DeleteOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import { ComponentesContext } from "../contextos/componentesContext";

export default function StructureAdmin({ children, breadcrumb, menuOption }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const URL = process.env.REACT_APP_URL_API_SOPORTE;

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [open, setOpen] = useState(false);

  const steps = [
    {
      title: 'Cambiar foto de perfil.',
      description: 'Dar click en la foto del perfil actual y seleccionar la nueva imagen.',
      cover: (
        <img
          alt="tour.png"
          src="https://soportearchivos.s3.us-west-2.amazonaws.com/rect177.png"
        />
      ),
      nextButtonProps: {description:"nuevo"},
      target: () => ref1.current,
    },
    {
      title: 'Cambiar contraseña',
      description: 'Dar click al botón Cambiar contraseña, posteriormente ingresar la contraseña actual y la nueva contraseña.',
      cover: (
        <img
          alt="tour.png"
          src="https://soportearchivos.s3.us-west-2.amazonaws.com/rect178.png"
        />
      ),
      target: () => ref1.current,
    },
  
  ];

  const {
    notificationsCounter,
    UpdateNotificationsCounter,
    UpdateDataLista,
    UpdatesettableHeight,
    ModalMessage,
    DataLista,
    tableHeight
  } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const [name, setName] = useState("?");
  const [nombreUsuario, setNombreUsuario] = useState(user.fullname);
  const [Correo, setCorreo] = useState(user.correo);
  const [loadingModal, setloadingModal] = useState(false);
  const [imagenUsuario, setimagenUsuario] = useState(user.imagen_usuario);

  const [DataListaNotificaciones, setDataListaNotificaciones] = useState([]);

  const { Header, Footer, Sider, Content } = Layout;
  const navigate = useNavigate();
  let notificationsInterval = useRef();
  const { Title } = Typography;
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const [imageUrl, setImageUrl] = useState("");
  const [modal1Open, setModal1Open] = useState(false);
  const [messageAvatar, setmessageAvatar] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [OpenmodalContrasena, setOpenmodalContrasena] = useState(false);

  const [isModalOpenDelete, setisModalOpenDelete] = useState(false);

  const [OpenmodalNotificaciones, setOpenmodalNotificaciones] = useState(false);

  const headerStyle = {
    textAlign: "right",
    color: "#fff",
    height: 50,
    paddingInline: 50,
    paddingTop: 4,
    lineHeight: "45px",
    backgroundColor: "#2b455a",
  };
  const contentStyle = {
    textAlign: "center",
    minHeight: 120,
    minWidth: 800,
    lineHeight: "120px",
    color: "#000000",
    backgroundColor: "#dedede",
    padding: 20,
  };

  const openNotificationAlerts = (count) => {
    let strMensaje = "";
    if (count == 1) {
      strMensaje = "Tienes " + count + " notificación pendiente de ver.";
    } else {
      strMensaje = "Tienes " + count + " notificaciones pendiente de ver.";
    }

    notification.open({
      message: "Notificación",
      description: (
        <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
          <BellOutlined style={{ fontSize: 30, color: "#f9dc06" }} />{" "}
          {strMensaje}
        </Typography.Title>
      ),
      className: "custom-class",
      style: {
        width: 600,
      },
      duration: 4,
    });
  };

  const getTicketsData = async () => {
    let ticketsData = await TicketsService.getTicketsSoporte();
    UpdateDataLista(ticketsData.data);
  };

  const getNotificacions = async () => {
    let notificationsData = await NotificationsService.getNotifications();

    if (notificationsData.estado == 500) {
      return false;
    }

    let totalNotificaciones = 0;
    for (let index = 0; index < notificationsData.data.length; index++) {
      if (notificationsData.data[index].mostrar == 1) {
        totalNotificaciones++;
      }
    }
    UpdateNotificationsCounter(notificationsData.data.length);

    if (totalNotificaciones > 0) {
      openNotificationAlerts(totalNotificaciones);
      getTicketsData();
    }
  };

  let NotificationBeginInterval = () => {
    notificationsInterval.current = setInterval(async () => {
      let usr = AuthService.getCurrentUser();
      if (usr === null || usr === undefined) {
        clearInterval(notificationsInterval.current);
        return false;
      }
      getNotificacions();
    }, 60000);
  };

  const handlePreview = async (file) => {
    const fileExtension = file.name.split(".").pop();

    switch (fileExtension.toLowerCase()) {
      case "apng":
      case "avif":
      case "gif":
      case "jpg":
      case "jpeg":
      case "jfif":
      case "pjpeg":
      case "pjp":
      case "png":
      case "svg":
      case "webp":
      case "bmp":
      case "ico":
      case "cur":
      case "tif":
      case "tiff":
        file.preview = file.url;
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
          file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
        break;
    }
  };

  useEffect(() => {
    let divHeight = document.getElementById("contenedor-admin").clientHeight;
    UpdatesettableHeight(divHeight);
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      setUser({});
      setName(" ");
      setmessageAvatar("");
      setCorreo("");
    } else {
      setUser(usr);
      setName(usr.primerNombre);
      setNombreUsuario(usr.fullname);
      setCorreo(usr.correo);
      setimagenUsuario(usr.imagen_usuario);
      setmessageAvatar("V");
      // const cachedImageUrl = localStorage.getItem("avatarImageUrl");
      // console.log(cachedImageUrl);
      // if (cachedImageUrl) {
      //   setImageUrl(cachedImageUrl);
      // } else {
      //   // Si no está almacenada en caché, cargar la imagen y guardar la URL en localStorage
      //   const newImageUrl = user.imagen_usuario; // Reemplaza con la URL de la imagen
      //   setImageUrl(newImageUrl);
      //   localStorage.setItem("avatarImageUrl", newImageUrl);
      // }
    }

    // let tipoUsuario = await ReturnUserTypeClass.ReturnUserType();
    // console.log(tipoUsuario);
    // if (tipoUsuario.estado != 500) {
    //   if (tipoUsuario.data.tipo == "2") {
    //     NotificationBeginInterval();
    //   }
    // }
    NotificationBeginInterval();
  }, []);

  const handleNext = (option) => {
    navigate(option);
  };

  const logOut = () => {
    AuthService.logout();
    navigate("/login");
  };

  const itemsMenu = [
    getItem(
      <a onClick={() => handleNext("/")}>Inicio</a>,
      "1",
      <HomeOutlined />
    ),
    getItem(
      <a onClick={() => handleNext("/lista-tickets-soporte")}>
        Lista de Tickets
      </a>,
      "2",
      <ProfileOutlined />
    ),

    getItem(
      <a onClick={() => handleNext("/catalogos")}>Catalogos</a>,
      "5",
      <ProfileOutlined />
    ),

    getItem(
      <a onClick={() => handleNext("/base-conocimientos")}>Base de conocimientos</a>,
      "6",
      <ReadOutlined />
    ),

    getItem(
      <a onClick={() => logOut()}>Cerrar Sesión</a>,
      "4",
      <LogoutOutlined />
    ),
  ];

  const handleAvatar = () => {
    setModal1Open(true);
  };

  const handleBeforeUpload = async (file) => {

    setloadingModal(true);

    let flagArchivoValido =
      await returnValidUploadFileClass.returnImageValidUploadFile(file.type);
    if (flagArchivoValido == false) {
      ModalMessage(
        "Mensaje",
        "El tipo de archivo que se desea adjuntar no es valido."
      );
      return false;
    }

    const fileSizeLimit = 1 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage("Mensaje","El archivo no puede pesar mas de 1MB." );
      return false; // Cancela la carga del archivo
    }
    //setloadingModal(true);
    return true;
  };

  const handleChange = async () => {
    //setloadingModal(true);
    setTimeout(async function () {
      let UsuariosData = await TicketsService.getDatosUsuarios();
      //console.log("Usuarios data",UsuariosData.dataEmp);
      LocalData.save('user',UsuariosData.dataEmp);
      setimagenUsuario(UsuariosData.dataEmp.imagen_usuario);
      //setFileList(DetalleAdjuntosData.data);
      setloadingModal(false);
    }, 6000);
    //setloadingModal(false);
  };

  const handleOpenModalContra = () => {
    setactualpassword("");
    setPassword("");
    setrepassword("");
    setOpenmodalContrasena(true);
  };


  const handleOpenModalNotificaciones = async () => {

    let ticketsData = await NotificationsService.getNotificationsActive();
    setDataListaNotificaciones(ticketsData.data);

    setOpenmodalNotificaciones(true);
  };

  

  const handleCloseModalContra = () => {
    setOpenmodalContrasena(false);
  };

  const handleCloseNotificaciones = () => {
    setOpenmodalNotificaciones(false);
  };

  const uploadButton = (
    <div>
      <Tooltip placement="bottom" title={"Click para cambiar imagen."}>
        <Avatar
          src={imagenUsuario}
          shape="square"
          size={84}
          icon={<UserOutlined />}
          style={{ backgroundColor: "#04142b" }}
        />
      </Tooltip>
    </div>
  );

  const [password, setPassword] = useState("");
  const [repassword, setrepassword] = useState("");
  const [actualpassword, setactualpassword] = useState("");

  const handleActualPasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(" ")) {
      setactualpassword(inputPassword);
    }
  };

  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(" ")) {
      setPassword(inputPassword);
    }
  };

  const handleRePasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(" ")) {
      setrepassword(inputPassword);
    }
  };

  const handleGuardarContrasena = async () => {
    if (password == "" || repassword == "" || actualpassword == "") {
      ModalMessage("Mensaje", "Las contraseñas no pueden estar en blanco.");

      return false;
    }

    if (password != repassword) {
      ModalMessage(
        "Mensaje",
        "Las contraseñas que ingresaste no coinciden. Asegúrate de escribir la misma contraseña en ambos campos para poder continuar."
      );
      return false;
    }
    const jsonPost = {
      contrasena_actual: actualpassword,
      contrasena: password,
    };
    let respuesta = await UsuariosService.postCambioContrasena(jsonPost);
    //console.log(respuesta);

    ModalMessage("Mensaje", respuesta.mensaje);

    if (respuesta.estado != 403) {
      setactualpassword("");
      setPassword("");
      setrepassword("");
    }
  };

  const HandlePrueba = (id_ticket) =>{

    navigate("/detalle-ticket-soporte", {
      state: {
        record: {
          id_ticket: id_ticket,
        },
      },
    });
  }


  const HandleBorrarNotificacion = async (id_ticket) => {
    
    const ticket = {
      id_ticket: id_ticket,
    };

    let notificationsData = await NotificationsService.postUpdateNotifications(ticket);

    let ticketsData = await NotificationsService.getNotificationsActive();
    setDataListaNotificaciones(ticketsData.data);

    getTicketsData();    

  }

  const ReturnNotificationCount = (cantidad) => {
    
    return (
      <Badge count={cantidad} />
    );
  };

  const columns = [
    {
      title: "No. Ticket",
      dataIndex: "id_ticket",
      key: "id_ticket",
      align: "center",
      width: 70,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id_ticket - b.id_ticket,
    },
    {
      title: "Solicita",
      dataIndex: "nombre_usuario",
      key: "nombre_usuario",
      width: 120,
    },
    
    {
      title: "Fecha",
      dataIndex: "fecha_alta",
      key: "fecha_alta",
      width: 100,
      sorter: (a, b) => moment(a.fecha_alta).unix() - moment(b.fecha_alta).unix(),
      render: (_, record) => (
        <>{moment(record.fecha_alta).format("YYYY-MM-DD, h:mm:ss a")}</>
      ),
    },

    {
      title: "Borrar",
      width: 60,
      dataIndex: "id_ticket",
      key: "id_ticket",
      align: "center",
      defaultSortOrder: "descend",
      render: (text) => (
        <Button
                type="primary"
                
                danger
                icon={<DeleteOutlined />}
                style={{backgroundColor:"#fc4c4c"}}
                onClick={() => HandleBorrarNotificacion(text)}
                
              />
      ),
    },
  ];

  const HandleClick = (record) =>{
    setvalorRow(record.ticket);
  }

  const handleModalCloseDelete = () => {
    setisModalOpenDelete(false);
  }


  const handleClickBorrarNotificaciones = () => {
    HandleBorrarNotificacion(0);
    setisModalOpenDelete(false);
  }


  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#04142b",
        },
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={[0, 48]}
      >
        <Layout>
          <Header style={headerStyle}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title="Notificaciones.">
              <Button
                type="primary"
                shape="circle"
                danger
                icon={<BellOutlined />}
                style={{backgroundColor:"#ff8000"}}
                onClick={() => handleOpenModalNotificaciones()}
                
              />
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label style={{fontSize:20, fontWeight:"bold"}}>SISTEMA DE TICKETS</label> &nbsp;&nbsp;
            <a  onClick={(e) => e.preventDefault()}>
              <Badge  size="small" count={notificationsCounter}>
                <Avatar
                  onClick={handleAvatar}
                  src={imagenUsuario}
                  size="large"
                  icon={<AppstoreOutlined />}
                  ref={ref1}
                  style={{marginTop:-10}}
                />
              </Badge>
            </a>
            
          </Header>
          <Layout style={{ height: window.innerHeight - 60 }}>
            {/* <Sider
              width={300}
              style={{ padding: 20, backgroundColor: "#ebeaea" }}
            >
              <div style={{ backgroundColor: "white", borderRadius: 10 }}>
                <Title level={4}>
                  &nbsp;&nbsp; <ToolOutlined /> Menu Soporte
                </Title>
                <Menu
                  style={{
                    width: 256,
                    fontWeight: "bold",
                    borderRadius: 10,
                    borderWidth: 0,
                  }}
                  selectedKeys={[menuOption]}
                  mode="inline"
                  items={itemsMenu}
                  inlineCollapsed={true}
                />
              </div>
            </Sider> */}
            <Content style={contentStyle}>
              <div
                id="contenedor-admin"
                className="content-main"
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  padding: 20,
                  minWidth: 800,
                }}
              >
                {children}
              </div>
            </Content>
          </Layout>
          
        </Layout>
        
        <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        

        <Modal
          style={{ top: 15, left: window.innerWidth / 2 - 280 }}
          open={modal1Open}
          onOk={() => setModal1Open(false)}
          onCancel={() => setModal1Open(false)}
          footer={<></>}
          
          maskClosable={false}
        >
          <Spin spinning={loadingModal} active={true}>
          <input
            type="file"
            id="target"
            style={{ display: "none" }}
            accept="image/png, image/gif, image/jpeg"
          />
          <Card
            style={{
              width: "100%",
              marginTop: 16,
            }}
            actions={[
              <Button
                style={{ color: "#696969" }}
                icon={<SettingOutlined />}
                size="large"
                onClick={handleOpenModalContra}
              >
                Cambiar contraseña
              </Button>,
              <Button
                onClick={(e) => {
                  logOut();
                }}
                style={{ color: "#696969", width: 200 }}
                icon={<CloseCircleOutlined />}
                size="large"
              >
                Cerrar Sesión
              </Button>,
            ]}
          >
            <table  cellSpacing={0} >
              <tbody>
                <tr>
                  <td rowSpan={2}>
                    <Upload
                      name="archivo"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${URL}cambia-imagen?token=${AuthService.getCurrentToken()}`}
                      beforeUpload={handleBeforeUpload}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </td>
                  <td style={{verticalAlign:"bottom"}}>
                    <b>Usuario:</b> {nombreUsuario}
                  </td>
                </tr>
                <tr>
                  <td style={{verticalAlign:"top"}}> <b>Correo:</b> {Correo}</td>
                </tr>
              </tbody>
            </table>
          </Card>
          </Spin>
        </Modal>

        <ModalContrasena
          style={{ top: 20, left: window.innerWidth / 2 - 280 }}
          open={OpenmodalContrasena}
          footer={<></>}
          closable={false}
        >
          <b>Contraseña actual:</b>
          <Input.Password
            placeholder="Ingrese contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={actualpassword}
            onChange={handleActualPasswordChange}
          />

          <b>Nueva contraseña:</b>
          <Input.Password
            placeholder="Ingrese contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={password}
            onChange={handlePasswordChange}
          />
          <b>Vuelve a ingresar la nueva contraseña:</b>
          <Input.Password
            placeholder="Ingrese nuevamente la contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={repassword}
            onChange={handleRePasswordChange}
          />
          <center>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Button
                      style={{ width: 190 }}
                      type="primary"
                      onClick={() => handleCloseModalContra()}
                      icon={<CloseOutlined />}
                    >
                      Salir
                    </Button>
                  </td>
                  <td>
                    <Button
                      style={{ width: 190 }}
                      type="primary"
                      onClick={() => handleGuardarContrasena()}
                      icon={<SaveOutlined />}
                    >
                      Guardar contraseña
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        </ModalContrasena>



        <Modal
          style={{ top: 20, left: window.innerWidth / 2 - 280 }}
          open={OpenmodalNotificaciones}
          footer={<></>}
          closable={false}
        >
          <center>
            <label style={{fontWeight:"bold", fontSize:20}}>
              NOTIFICACIONES
            </label>
          </center>
          
          <Table
          style={{minWidth:300, marginTop:10}}
          size="middle"
          columns={columns}
          dataSource={DataListaNotificaciones}
          pagination={false}
          scroll={{
            y: tableHeight - 150,
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onMouseEnter: (event) => {HandleClick(record)},
          //   };
          // }}
        />
          <br></br>
          <center>
            <table>
              <tbody>
                <tr>
                  <td>
                  <Button
                      style={{ width: 260 }}
                      type="primary"
                      onClick={() => setisModalOpenDelete(true)}
                      icon={<DeleteOutlined />}
                      danger
                    >
                      Borrar todas las notificaciones
                    </Button>
                  </td>
                  <td>
                  <Button
                      style={{ width: 190 }}
                      type="primary"
                      onClick={() => handleCloseNotificaciones()}
                      icon={<CloseOutlined />}
                    >
                      Cerrar
                    </Button>
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        </Modal>



        <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: '#081b3e', marginBottom: 10 }}
            >
              <FileExcelOutlined style={{ color: '#4c4cbc' }} /> &nbsp;{' '}
              <label style={{ color: '#4c4cbc' }}>Borrar Notificaciones.</label>
            </Typography.Title>
          </div>
        }
        /*
        closable={false}
        maskClosable={false}
        */
        open={isModalOpenDelete}
        onCancel={handleModalCloseDelete}
        footer={[
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              danger
              onClick={handleClickBorrarNotificaciones}
              icon={<DeleteOutlined />}
            >
              Si
            </Button>
            <Button
              type="primary"
              onClick={handleModalCloseDelete}
              icon={<CloseOutlined />}
            >
              No
            </Button>
          </div>,
        ]}
        className="modal-pld"
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginLeft: -20,
            fontSize: 18,
          }}
        >
          Esta segur@ de borrar todas las notificaciones?
        </div>
      </Modal>

      </Space>
    </ConfigProvider>
  );
}
