import React from 'react';
import logo from '../asset/images/LogoTickets.png';

export default function Logo() {
  return (
    <img
      src={logo}
      alt="Fínamo"
      className="img-fluid"
      width="210"
      height="55"
    />
  );
}
